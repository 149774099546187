import ApiService from "../../service/ApiService";
import JwtService from "../../service/JwtService";
import {Actions, Mutations} from "../enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

export interface User {
    id: string;
    name: string;
    surname: string;
    email: string;
    password: string;
    api_token: string;
    role_user: string;
    application_master: string
    customer: string
    is_hotline: boolean
}

export interface UserAuthInfo {
    errors: unknown;
    user: User;
    isAuthenticated: boolean;
}

export interface Tools {
    statusImport: boolean,
    countStatusImport: string
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
    errors = {};
    user = {} as User;
    isAuthenticated = !!JwtService.getToken();
    tools = {} as Tools;

    /**
     * Get Tools object
     * @returns Tools
     */
    get toolsObject(): Tools {
        return this.tools;
    }

    /**
     * Get current user object
     * @returns User
     */
    get currentUser(): User {
        return this.user;
    }

    /**
     * Verify user authentication
     * @returns boolean
     */
    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error: any) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_AUTH](user: User) {
        this.isAuthenticated = true;
        this.user = user;
        this.errors = {};
        JwtService.saveToken(user.api_token);
        // if (user.role_user) {
        //     UserService.saveRoleUser(user.role_user[0])
        //     UserService.saveApplicationMaster(user.application_master)
        // }
    }

    @Mutation
    [Mutations.SET_TOOLS](tools: Tools) {
        this.tools = tools;

    }

    @Mutation
    [Mutations.SET_USER](user: User) {
        this.user = user;
    }

    @Mutation
    [Mutations.SET_PASSWORD](password: string) {
        this.user.password = password;
    }

    @Mutation
    [Mutations.PURGE_AUTH]() {
        this.isAuthenticated = false;
        this.user = {} as User;
        this.errors = [];
        JwtService.destroyToken();
    }

    @Action
    [Actions.GET_USER]() {
        return this.user
    }

    @Action
    [Actions.LOGIN](credentials: object) {
        return ApiService.post("login_check", credentials)
            .then(({data}) => {
                return this.context.dispatch(Actions.GET_USER_BY_TOKEN, {
                    token: data.token,
                    'customHeader': false
                }).then((response) => {

                    return this.context.commit(Mutations.SET_AUTH, {
                        name: response.firstname,
                        surname: response.lastname,
                        email: response.email,
                        password: null,
                        api_token: data.token,
                        role_user: data.roles,
                        application_master: data.application_master,
                        customer: data.customer,
                        id: data.id,
                        is_hotline: data.is_hotline
                    });
                })

            })
            .catch(({response}) => {
                if ( response?.data?.message){
                    this.context.commit(Mutations.SET_ERROR, response?.data?.message);
                }
            });
    }

    @Action
    [Actions.LOGOUT]() {
        this.context.commit(Mutations.PURGE_AUTH);
    }

    @Action
    [Actions.GET_USER_BY_TOKEN](data: any) {
        ApiService.setCustomToken(data.token);
        return ApiService.get("me", data.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.FORGOT_PASSWORD](payload: object) {
        ApiService.removeAuthHeader();
        return ApiService.post("forgot-password/", payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.FORGOT_PASSWORD_RESET_PWD](payload: any) {
        ApiService.removeAuthHeader();
        return ApiService.post("forgot-password/" + payload.token, payload.password)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.VERIFY_AUTH](data: any) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            return ApiService.get("me", data.customHeader)
                .then(({data}) => {
                    return this.context.commit(Mutations.SET_AUTH, {
                        name: data.firstname,
                        surname: data.lastname,
                        email: data.email,
                        password: null,
                        api_token: JwtService.getToken(),
                        role_user: data.roles,
                        application_master: data.application_master,
                        customer: data.customer,
                        id: data.id,
                        is_hotline: data.is_hotline
                    });
                })
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    this.context.commit(Mutations.PURGE_AUTH);
                });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.PROFILE](data: any) {
        return ApiService.get("me", data.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });

    }


    @Action
    [Actions.GET_SITES](objData: any) {
        // ApiService.setCustomToken(token);
        return ApiService.get("sites/tree", objData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_CUSTOMERS_SITES](objData: any) {
        const params = {} as object;


        if (typeof objData.page !== "undefined") {
            // @ts-ignore
            params['page'] = objData.page
        }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }


        // ApiService.setCustomToken(token);
        return ApiService.get("customers" + queryString, objData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_CUSTOMERS_SITE_TREE](objData: any) {
        // ApiService.setCustomToken(token);

        const params = {} as object;


        if (typeof objData.page !== "undefined") {
            // @ts-ignore
            params['page'] = objData.page
        }
        if (typeof objData.customer !== "undefined") {
            // @ts-ignore
            params['customer'] = objData.customer
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }

        if (this.isAuthenticated){
            return ApiService.get("customers/tree" + queryString, objData.customHeader)
                .then(({data}) => {
                    return data;
                })
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                });
        }

    }

    @Action
    [Actions.GET_CUSTOMERS_BY_ID](customer: any) {
        // ApiService.setCustomToken(token);
        return ApiService.get("customers/" + customer.id, customer.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_COUNTRIES](objData: any) {
        // ApiService.setCustomToken(token);
        return ApiService.get("countries", objData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_CATEGORIES](objData: any) {
        // ApiService.setCustomToken(token);
        const params = {} as object;


        if (objData.type) {
            // @ts-ignore
            params['type'] = objData.type
        }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }


        return ApiService.get("categories" + queryString, objData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_CATEGORIES_TREE](objData: any) {
        // ApiService.setCustomToken(token);
        const params = {} as object;


        // if ( objData.type) {
        //     // @ts-ignore
        //     params['type'] = objData.type
        // }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }


        return ApiService.get("categories/tree" + queryString, objData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.ADD_CUSTOMER](formData: object) {
        return ApiService.post("customers", formData)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.message);
            });
    }

    @Action
    [Actions.PUT_CUSTOMER](customer: any) {
        // ApiService.setCustomToken(token);
        return ApiService.put("customers/" + customer.id, customer)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }


    @Action
    [Actions.ADD_SITE](formData: object) {
        return ApiService.post("sites", formData)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.message);
            });
    }

    @Action
    [Actions.GET_SITES_BY_CUSTOMER](objectData: any) {
        // ApiService.setCustomToken(token);

        const params = {} as object;
        if (objectData.idCustomer) {
            // @ts-ignore
            params['customer'] = objectData.idCustomer;
        }

        if (typeof objectData.page !== "undefined") {
            // @ts-ignore
            params['page'] = objectData.page
        }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }


        return ApiService.get("sites" + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_SITES_ALL](objectData: any) {
        // ApiService.setCustomToken(token);
        const params = {} as object;


        if (objectData.withStat) {
            // @ts-ignore
            params['with_stat'] = objectData.withStat
        }

        if (objectData.page) {
            // @ts-ignore
            params['page'] = objectData.page
        }

        if (objectData.limit) {
            // @ts-ignore
            params['limit'] = objectData.limit
        }

        if (objectData.entity) {
            // @ts-ignore
            params['entity'] = objectData.entity
        }
        if (objectData.type) {
            // @ts-ignore
            params['type'] = objectData.type
        }
        if (objectData.customer) {
            // @ts-ignore
            params['customer'] = objectData.customer
        }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }


        return ApiService.get("sites" + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_SITES_STAT](objectData: any) {
        // ApiService.setCustomToken(token);
        const params = {} as object;
        if (objectData.page) {
            // @ts-ignore
            params['page'] = objectData.page
        }

        if (objectData.limit) {
            // @ts-ignore
            params['limit'] = objectData.limit
        }

        if (objectData.entity) {
            // @ts-ignore
            params['entity'] = objectData.entity
        }
        if (objectData.type) {
            // @ts-ignore
            params['type'] = objectData.type
        }
        if (objectData.graph_report) {
            // @ts-ignore
            params['graph_report'] = objectData.graph_report
        }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }


        return ApiService.get("sites/with_stat" + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_USERS](objData: any) {
        // ApiService.setCustomToken(token);
        const params = {} as object;

        //@todo change it to forEach
        if (objData.page) {
            // @ts-ignore
            params['page'] = objData.page
        }
        if (objData.limit) {
            // @ts-ignore
            params['limit'] = objData.limit
        }
        if (objData.customer) {
            // @ts-ignore
            params['customer'] = objData.customer
        }
        if (objData.firstname) {
            // @ts-ignore
            params['firstname'] = objData.firstname
        }
        if (objData.lastname) {
            // @ts-ignore
            params['lastname'] = objData.lastname
        }
        if (objData.department) {
            // @ts-ignore
            params['department'] = objData.department
        }
        if (objData.email) {
            // @ts-ignore
            params['email'] = objData.email
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }


        return ApiService.get("users" + queryString, objData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_USER_BY_ID](user: any) {
        // ApiService.setCustomToken(token);
        return ApiService.get("users/" + user.id, user.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.PUT_USER](user: any) {
        // ApiService.setCustomToken(token);
        return ApiService.put("users/" + user.id, user)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.ADD_USER](user: any) {
        // ApiService.setCustomToken(token);
        return ApiService.post("users", user)
            .then(({data}) => {
                this.context.commit(Mutations.SET_ERROR, '')
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.status);
            });
    }


    @Action
    [Actions.DELETE_USER](id: any) {
        // ApiService.setCustomToken(token);
        return ApiService.delete("users/" + id)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }


    @Action
    [Actions.GET_DOCUMENTS](objectData: any) {
        // ApiService.setCustomToken(token);
        const params = [] as Array<any>;
        if (objectData.id) {
            // @ts-ignore
            params.push('id=' + objectData.id)
            // params['theme'] = objectData.theme;
        }
        if (objectData.theme) {
            // @ts-ignore
            params.push('theme=' + objectData.theme)
            // params['theme'] = objectData.theme;
        }
        if (objectData.domain) {
            // @ts-ignore
            // params['domain'] = objectData.domain
            params.push('domain=' + objectData.domain)
        }
        if (objectData.sub_domain) {
            // @ts-ignore
            // params['sub_domain'] = objectData.sub_domain
            params.push('sub_domain=' + objectData.sub_domain)
        }
        if (objectData.text) {
            // @ts-ignore
            // params['term'] = objectData.text
            params.push('term=' + objectData.text)
        }
        if (objectData.idSite) {

            // @ts-ignore
            // params['affectations.site'] = objectData.idSite

            // params['affectations.site'] = objectData.idSite
            params.push('affectations.site=' + objectData.idSite)
        }
        if (objectData.withStat) {
            // @ts-ignore
            // params['with_stat'] = objectData.withStat
            params.push('with_stat=' + objectData.withStat)
        }
        if (typeof objectData.page !== "undefined") {
            // @ts-ignore
            // params['page'] = objectData.page
            params.push('page=' + objectData.page)
        }
        if (objectData.dated_at) {
            // @ts-ignore
            // @ts-ignore
            params.push('dated_at[before]=' + objectData.dated_at)
            // params['dated_at']['before'] = objectData.dated_at
        }
        if (objectData.dated_at) {
            // @ts-ignore
            // if(typeof  params['dated_at'] === 'undefined') {
            //     // @ts-ignore
            //     params['dated_at'] = {}
            // }

            // @ts-ignore
            // params['dated_at']['after'] = objectData.dated_at
            params.push('dated_at[after]=' + objectData.dated_at)
        }
        if (objectData.last_updated_at) {
            // @ts-ignore
            // if(typeof  params['last_updated_at'] === 'undefined') {
            //     // @ts-ignore
            //     params['last_updated_at'] = {}
            // }
            // @ts-ignore
            // params['last_updated_at']['before'] = objectData.last_updated_at
            params.push('last_updated_at[before]=' + objectData.last_updated_at)
        }
        if (objectData.last_updated_at) {
            // @ts-ignore
            // if(typeof  params['last_updated_at'] === 'undefined') {
            //     // @ts-ignore
            //     params['last_updated_at'] = {}
            // }
            // @ts-ignore
            // params['last_updated_at']['after'] = objectData.
            params.push('last_updated_at[before]=' + objectData.last_updated_at)
        }

        if (typeof objectData.limit !== "undefined") {
            // @ts-ignore
            // params['limit'] = objectData.limit
            params.push('limit=' + objectData.limit)
        }


        // let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        let queryString = params.join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }


        return ApiService.get("documents" + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });


    }

    @Action
    [Actions.GET_DOCUMENTS_STAT](objectData: any) {
        const params = [] as Array<any>;
        if (objectData.theme) {
            params.push('theme=' + objectData.theme)
        }
        if (objectData.domain) {
            params.push('domain=' + objectData.domain)
        }
        if (objectData.sub_domain) {
            params.push('sub_domain=' + objectData.sub_domain)
        }
        if (objectData.text) {
            params.push('term=' + objectData.text)
        }
        if (objectData.idSite) {
            params.push('entity=' + objectData.idSite)
        }
        if (objectData.withStat) {
            params.push('with_stat=' + objectData.withStat)
        }
        if (typeof objectData.page !== "undefined") {
            params.push('page=' + objectData.page)
        }
        if (objectData.dated_at) {
            params.push('dated_at[before]=' + objectData.dated_at)
        }
        if (objectData.dated_at) {
            params.push('dated_at[after]=' + objectData.dated_at)
        }
        if (objectData.last_updated_at) {
            params.push('last_updated_at[before]=' + objectData.last_updated_at)
        }
        if (objectData.last_updated_at) {
            params.push('last_updated_at[before]=' + objectData.last_updated_at)
        }
        if (typeof objectData.limit !== "undefined") {
            params.push('limit=' + objectData.limit)
        }
        if (objectData.rate_progress) {
            params.push('rate_progress=' + objectData.rate_progress)
        }
        if (objectData.rate_conformity) {
            params.push('rate_conformity=' + objectData.rate_conformity)
        }

        let queryString = params.join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        return ApiService.get("documents/with_stat" + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_STATUS_DOCUMENT]() {

        return ApiService.get("document_statuses", false)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });


    }


    @Action
    [Actions.GET_SECTIONS](objectData: any) {
        // ApiService.setCustomToken(token);
        const params = {} as object;
        if (objectData.document) {
            // @ts-ignore
            params['document'] = objectData.document;
        }
        if (objectData.section) {
            // @ts-ignore
            params['section'] = objectData.section
        }
        if (objectData.site) {
            // @ts-ignore
            params['affectation.site'] = objectData.site
        }
        if (objectData.withStat) {
            // @ts-ignore
            params['with_stat'] = objectData.withStat
        }
        if (typeof objectData.page !== "undefined") {
            // @ts-ignore
            params['page'] = objectData.page
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }


        return ApiService.get("sections" + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_SECTIONS_STAT](objectData: any) {
        // ApiService.setCustomToken(token);
        const params = {} as object;
        if (objectData.document) {
            // @ts-ignore
            params['document'] = objectData.document;
        }
        if (objectData.section) {
            // @ts-ignore
            params['section'] = objectData.section
        }
        if (objectData.site) {
            // @ts-ignore
            params['entity'] = objectData.site
        }
        if (objectData.withStat) {
            // @ts-ignore
            params['with_stat'] = objectData.withStat
        }
        if (typeof objectData.page !== "undefined") {
            // @ts-ignore
            params['page'] = objectData.page
        }
        if (typeof objectData.limit !== "undefined") {
            // @ts-ignore
            params['limit'] = objectData.limit
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }


        return ApiService.get("sections/with_stat" + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_SECTION_BY_ID](objectData: any) {
        // ApiService.setCustomToken(token);
        return ApiService.get("sections/" + objectData.id, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.POST_SECTION](section: any) {
        // ApiService.setCustomToken(token);
        return ApiService.post("sections", section)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }


    @Action
    [Actions.PUT_SECTION](section: any) {
        // ApiService.setCustomToken(token);
        return ApiService.put("sections/" + section.id, section)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.DELETE_SECTION](idSection: any) {
        // ApiService.setCustomToken(token);
        return ApiService.delete("sections/" + idSection)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_COMPLIANCES](objectData: any) {
        // ApiService.setCustomToken(token);
        const params = {} as object;
        if (objectData.document) {
            // @ts-ignore
            params['document'] = objectData.document;
        }
        if (objectData.section) {
            // @ts-ignore
            params['section'] = objectData.section
        }
        if (objectData.evaluationSite) {
            // @ts-ignore
            params['evaluations.site'] = objectData.evaluationSite
        }
        if (objectData.evaluationDocument) {
            // @ts-ignore
            params['evaluations.document'] = objectData.evaluationDocument
        }
        if (typeof objectData.page !== "undefined") {
            // @ts-ignore
            params['page'] = objectData.page
        }
        if (typeof objectData.limit !== "undefined") {
            // @ts-ignore
            params['limit'] = objectData.limit
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }

        return ApiService.get("compliances" + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_COMPLIANCE_BY_ID](objectData: any) {
        // ApiService.setCustomToken(token);
        return ApiService.get("compliances/" + objectData.id, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.PUT_COMPLIANCE](compliance: any) {
        // ApiService.setCustomToken(token);
        return ApiService.put("compliances/" + compliance.id, compliance)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.ADD_COMPLIANCE](compliance: any) {
        // ApiService.setCustomToken(token);
        return ApiService.post("compliances", compliance)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }


    @Action
    [Actions.DELETE_COMPLIANCE](idCompliance: any) {
        // ApiService.setCustomToken(token);
        return ApiService.delete("compliances/" + idCompliance)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }


    @Action
    [Actions.ADD_DOCUMENT](formData: object) {
        return ApiService.post("documents", formData)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.message);
            });
    }

    @Action
    [Actions.GET_DOCUMENT_BY_ID](document: any) {
        // ApiService.setCustomToken(token);
        return ApiService.get("documents/" + document.id, document.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.PUT_DOCUMENT](objectData: any) {
        // ApiService.setCustomToken(token);
        return ApiService.put("documents/" + objectData.id, objectData)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }


    @Action
    [Actions.GET_SITES_BY_ID](objectData: any) {
        // ApiService.setCustomToken(token);
        // idSite = '34';
        return ApiService.get('sites/' + objectData.id, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_SITE_BY_ID](objectData: any) {
            if (this.isAuthenticated && objectData?.id && objectData?.id != 'null'){
                    return ApiService.get('site/' + objectData.id, objectData.customHeader)
                    .then(({data}) => {
                        return data;
                    })
                    .catch(({response}) => {
                        this.context.commit(Mutations.SET_ERROR, response.data.errors);
                    });
        }
    }

    @Action
    [Actions.PUT_SITE](site: any) {
        // ApiService.setCustomToken(token);
        // idSite = '34';


        return ApiService.put('sites/' + site.idSite, site.site)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_STATUS_EVALUATION](objectData: any) {
        // ApiService.setCustomToken(token);
        // idSite = '34';

        const params = {} as object;


        if (objectData.withStat) {
            // @ts-ignore
            params['with_stat'] = objectData.withStat
        }

        if (objectData.entity) {
            // @ts-ignore
            params['entity'] = objectData.entity
        }


        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }

        return ApiService.get('evaluation_statuses' + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.HISTORIC_EVALUATION](objectData: any) {
        // ApiService.setCustomToken(token);
        // idSite = '34';

        const params = {} as object;

        if (typeof objectData.page !== "undefined") {
            // @ts-ignore
            params['page'] = objectData.page
        }
        if (typeof objectData.limit !== "undefined") {
            // @ts-ignore
            params['limit'] = objectData.limit
        }


        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }

        return ApiService.get('evaluations/' + objectData.idEvaluation + '/histories' + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_STATUS_EVALUATION_STAT](objectData: any) {


        const params = {} as object;

        if (objectData.entity) {
            // @ts-ignore
            params['entity'] = objectData.entity
        }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        if (objectData?.entity.length>0 && objectData.entity[0] && objectData.entity[0] != 'null') {
            return ApiService.post('evaluation_statuses/with_stat', params)
                .then(({data}) => {
                    return data;
                })
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                });
        }
    }

    @Action
    [Actions.GET_EVALUATION_BY_ID](obj: any) {
        return ApiService.get('evaluations/' + obj.idEval, obj.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.PUT_EVALUATION](evaluation: any) {
        const params = {} as object;
        if (evaluation.action_plans) {
            // @ts-ignore
            params['action_plans'] = evaluation.action_plans
        }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        return ApiService.put('evaluations/' + evaluation.id + queryString, evaluation)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.DOCUMENTS_WITH_SITE_STAT](objectData: any) {
        // ApiService.setCustomToken(token);
        const params = {} as object;
        if (objectData.entity) {
            // @ts-ignore
            params['entity'] = objectData.entity;
        }
        if (objectData.customer) {
            // @ts-ignore
            params['customer'] = objectData.customer;
        }

        if (typeof objectData.page !== "undefined") {
            // @ts-ignore
            params['page'] = objectData.page
        }
        if (typeof objectData.limit !== "undefined") {
            // @ts-ignore
            params['limit'] = objectData.limit
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        if (objectData?.entity.length>0 && objectData.entity[0] && objectData.entity[0] != 'null'){
            return ApiService.post("documents/with_site_stat", params)
                .then(({data}) => {

                    return data;
                })
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                });
        }

    }

    @Action
    [Actions.DOCUMENTS_RECAP_EVAL_STAT](objectData: any) {
        // ApiService.setCustomToken(token);
        const params = {} as object;
        if (objectData.entity) {
            // @ts-ignore
            params['entity'] = objectData.entity;
        }
        if (objectData.theme) {
            // @ts-ignore
            params['theme'] = objectData.theme;
        }
        if (objectData.domain) {
            // @ts-ignore
            params['domain'] = objectData.domain;
        }
        if (objectData.sub_domain) {
            // @ts-ignore
            params['sub_domain'] = objectData.sub_domain;
        }
        if (objectData.term) {
            // @ts-ignore
            params['term'] = objectData.term;
        }

        if (typeof objectData.page !== "undefined") {
            // @ts-ignore
            params['page'] = objectData.page
        }
        if (typeof objectData.limit !== "undefined") {
            // @ts-ignore
            params['limit'] = objectData.limit
        }
        if (objectData.rate_progress) {
            // @ts-ignore
            params['rate_progress'] = objectData.rate_progress
        }
        if (objectData.rate_conformity) {
            // @ts-ignore
            params['rate_conformity'] = objectData.rate_conformity
        }
        if (objectData.parent_site_name_4) {
            // @ts-ignore
            params['parent_site_name_4'] = objectData.parent_site_name_4
        }
        if (objectData.parent_site_name_3) {
            // @ts-ignore
            params['parent_site_name_3'] = objectData.parent_site_name_3
        }
        if (objectData.parent_site_name_2) {
            // @ts-ignore
            params['parent_site_name_2'] = objectData.parent_site_name_2
        }
        if (objectData.parent_site_name_1) {
            // @ts-ignore
            params['parent_site_name_1'] = objectData.parent_site_name_1
        }
        if (objectData.parent_site_name) {
            // @ts-ignore
            params['parent_site_name'] = objectData.parent_site_name
        }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }


        return ApiService.get("documents/recap_eval_stat" + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.EXPORTS_POST](formData: object) {

        return ApiService.post("exports", formData)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.message);
            });
    }

    @Action
    [Actions.AFFECTATION_DOCUMENTS](objectData: any) {
        const params = {} as object;
        if (objectData.idSite) {
            // @ts-ignore
            params['site'] = objectData.idSite;
        }

        if (typeof objectData.page !== "undefined") {
            // @ts-ignore
            params['page'] = objectData.page
        }
        if (typeof objectData.limit !== "undefined") {
            // @ts-ignore
            params['limit'] = objectData.limit
        }

        if (typeof objectData.isApplicable !== "undefined") {
            // @ts-ignore
            params['isApplicable'] = objectData.isApplicable
        }
        if (typeof objectData.document_id !== "undefined") {
            // @ts-ignore
            params['document'] = objectData.document_id
        }
        if (typeof objectData.user_id !== "undefined") {
            // @ts-ignore
            params['site.user_sites.user'] = objectData.user_id
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }


        return ApiService.get("affectations" + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }
    @Action
    [Actions.AFFECTATIONS_LIST](objectData: any) {
        const params = {} as object;

        if (typeof objectData.document_id !== "undefined") {
            // @ts-ignore
            params['document'] = objectData.document_id
        }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        return ApiService.get("affectations_list" + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.AFFECTATIONS_APPLICABLE](objectData: any) {
        const params = {} as object;
        if (objectData.idSite) {
            // @ts-ignore
            params['listSites'] = objectData.idSite;
        }

        if (typeof objectData.page !== "undefined") {
            // @ts-ignore
            params['page'] = objectData.page
        }

        if (typeof objectData.limit !== "undefined") {
            // @ts-ignore
            params['limit'] = objectData.limit
        }
        if (objectData.is_applicable) {
            // @ts-ignore
            params['is_applicableX'] = objectData.is_applicable
        }

        if (objectData.theme) {
            // @ts-ignore
            // params.push('theme=' + objectData.theme)
            params['themeX'] = objectData.theme;
        }
        if (objectData.domain) {
            // @ts-ignore
            params['domainX'] = objectData.domain
            // params.push('domain=' + objectData.domain)
        }
        if (objectData.sub_domain) {
            // @ts-ignore
            params['sub_domainX'] = objectData.sub_domain
            // params.push('sub_domain=' + objectData.sub_domain)
        }

        if (objectData.text) {
            // @ts-ignore
            params['termX'] = objectData.text
            // params.push('term=' + objectData.text)
        }
        if (objectData.rate_progress) {
            // @ts-ignore
            params['rate_progressX'] = objectData.rate_progress
        }
        if (objectData.rate_conformity) {
            // @ts-ignore
            params['rate_conformityX'] = objectData.rate_conformity
        }

        if (objectData.parent_site_name_4) {
            // @ts-ignore
            params['parent_site_name_4'] = objectData.parent_site_name_4
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        return ApiService.post("affectations/documents_report", params)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    //@todo fix post 2 parametrs
    @Action
    [Actions.IMPORT_FILE](formData: object) {
        return ApiService.postFormData("imports/import", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.message);
            });
    }

    //@todo  fix status appel
    @Action
    [Actions.GET_STATUS_IMPORT](objectData: any) {
        // ApiService.setCustomToken(token);
        const params = {} as object;
        if (objectData.site_id) {
            // @ts-ignore
            params['site_id'] = objectData.site_id;
        }

        if (typeof objectData.page !== "undefined") {
            // @ts-ignore
            params['page'] = objectData.page
        }
        if (typeof objectData.limit !== "undefined") {
            // @ts-ignore
            params['limit'] = objectData.limit
        }
        if (objectData.document_id) {
            // @ts-ignore
            params['document_id'] = objectData.document_id
        }
        if (objectData.model) {
            // @ts-ignore
            params['model'] = objectData.model
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }


        return ApiService.get("imports" + queryString + '&status[]=processing&status[]=pending', objectData.customHeader)
            .then(({data}) => {
                return this.context.commit(Mutations.SET_TOOLS, {
                    countStatusImport: data['hydra:totalItems']
                });
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.ADD_SITES_CLONE](formData: object) {
        return ApiService.post("sites/clone", formData)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.message);
            });
    }

    @Action
    [Actions.GET_ACTION_PLAN](objectData: any) {
        // ApiService.setCustomToken(token);
        const params = {} as object;
        // if (typeof objectData.user_id !== "undefined") {
        //     // @ts-ignore
        //     params['site.userSites.user'] = objectData.user_id
        // }
        if (typeof objectData.page !== "undefined") {
            // @ts-ignore
            params['page'] = objectData.page
        }
        if (typeof objectData.limit !== "undefined") {
            // @ts-ignore
            params['limit'] = objectData.limit
        }
        if (typeof objectData.evaluation !== "undefined") {
            // @ts-ignore
            params['evaluation'] = objectData.evaluation
        }

        if (typeof objectData.site !== "undefined") {
            // @ts-ignore
             params['filter_site'] = objectData.site
        }

        if (objectData.title) {
            // @ts-ignore
            params['title'] = objectData.title
        }

        if (objectData.start_date_range_start) {
            // @ts-ignore
            params['start_date_range_start'] = objectData.start_date_range_start
            // params['start_date[after]'] = objectData.start_date
        }

        if (objectData.start_date_range_end) {
            // @ts-ignore
            params['start_date_range_end'] = objectData.start_date_range_end
            // params['start_date[after]'] = objectData.start_date
        }
        if (objectData.end_date_range_start) {
            // @ts-ignore
            // params['end_date[after]'] = objectData.end_date
            params['end_date_range_start'] = objectData.end_date_range_start
        }
        if (objectData.end_date_range_end) {
            // @ts-ignore
            // params['end_date[after]'] = objectData.end_date
            params['end_date_range_end'] = objectData.end_date_range_end
        }
        if (objectData.revised_end_date_range_start) {
            // @ts-ignore
            // params['revised_end_date[after]'] = objectData.revised_end_date
            params['revised_end_date_range_start'] = objectData.revised_end_date_range_start
        }

        if (objectData.revised_end_date_range_end) {
            // @ts-ignore
            // params['revised_end_date[after]'] = objectData.revised_end_date
            params['revised_end_date_range_end'] = objectData.revised_end_date_range_end
        }

        if (objectData.user_pilot) {
            // @ts-ignore
            params['user_pilot'] = objectData.user_pilot
        }
        if (objectData.user_in_charge) {
            // @ts-ignore
            params['user_in_charge'] = objectData.user_in_charge
        }

        if (objectData.priority) {
            // @ts-ignore
            params['priority'] = objectData.priority
        }
        if (objectData.rate_progress_start) {
            // @ts-ignore
            params['rateProgress[gte]'] = objectData.rate_progress_start
        }
        if (objectData.rate_progress_end) {
            // @ts-ignore
            params['rateProgress[lte]'] = objectData.rate_progress_end
        }



        // if (objectData.rate_progress) {
        //     // @ts-ignore
        //     params['rate_progress'] = objectData.rate_progress
        // }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }


        return ApiService.get("action_plans" + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.ADD_ACTION_PLAN](formData: object) {
        return ApiService.post("action_plans", formData)

            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.message);
            });
    }

    @Action
    [Actions.GET_ACTION_PLAN_BY_ID](objectData: any) {

        return ApiService.get("action_plans/" + objectData.idAP, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.ADD_TASK_AP](formData: object) {
        return ApiService.post("action_plan_tasks", formData)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.message);
            });
    }

    @Action
    [Actions.PUT_TASKS_AP](objectData: any) {
        // ApiService.setCustomToken(token);
        return ApiService.put("action_plan_tasks/" + objectData.id, objectData)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_TASKS_AP](objectData: any) {
        // ApiService.setCustomToken(token);
        const params = {} as object;
        if (typeof objectData.page !== "undefined") {
            // @ts-ignore
            params['page'] = objectData.page
        }
        if (typeof objectData.limit !== "undefined") {
            // @ts-ignore
            params['limit'] = objectData.limit
        }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        return ApiService.get("action_plan_tasks" + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_TASK_AP_BY_ID](objectData: any) {

        return ApiService.get("action_plan_tasks/" + objectData.idTask, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.PUT_ACTION_PLAN](objectData: any) {
        // ApiService.setCustomToken(token);
        return ApiService.put("action_plans/" + objectData.id, objectData)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.ADD_COMMENT_AP](formData: object) {
        return ApiService.post("action_plan_comments", formData)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.message);
            });
    }

    @Action
    [Actions.GET_COMMENT_AP](objectData: any) {
        // ApiService.setCustomToken(token);
        const params = {} as object;
        if (typeof objectData.page !== "undefined") {
            // @ts-ignore
            params['page'] = objectData.page
        }
        if (typeof objectData.limit !== "undefined") {
            // @ts-ignore
            params['limit'] = objectData.limit
        }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        return ApiService.get("action_plan_comments" + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_COMMENT_AP_BY_ID](objectData: any) {

        return ApiService.get("action_plan_comments/" + objectData.idComment, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.PUT_COMMENT_AP](objectData: any) {
        // ApiService.setCustomToken(token);
        return ApiService.put("action_plan_comments/" + objectData.id, objectData)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.DELETE_COMPLIANCE_AP](id: any) {
        // ApiService.setCustomToken(token);
        return ApiService.delete("action_plan_compliances/" + id)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.DELETE_EVALUATION_AP](objectData: any) {
        return ApiService.delete("action_plans/clone/" + objectData.idPA + '/' + objectData.idEval)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_USERS_AP](objectData: any) {
        return ApiService.get("users_PA/" + objectData.site_id, objectData.customHeader)
            // return ApiService.get("users_PA" , objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.POST_RANKED_SECTION](section: any) {
        // ApiService.setCustomToken(token);
        return ApiService.post("sections/reorder", section)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.PUT_RANKED_SECTION](section: any) {
        // ApiService.setCustomToken(token);
        return ApiService.put("sections/reorder/" + section.id, section)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_CATEGORIES_TREE_FILTER](objData: any) {
        // ApiService.setCustomToken(token);
        const params = {} as object;
        if (objData.site) {
            // @ts-ignore
            params['id_site'] = objData.site
        }
        if (objData.is_applicable) {
            // @ts-ignore
            params['is_applicable'] = objData.is_applicable
        }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        return ApiService.get("categories/tree/filter" + queryString, objData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.DELETE_AP](id: any) {
        return ApiService.delete("action_plans/" + id)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.DELETE_AP_TASK](id: any) {
        return ApiService.delete("action_plan_tasks/" + id)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.DELETE_AP_COMMENT](id: any) {
        return ApiService.delete("action_plan_comments/" + id)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.UPLOAD_FILE](object: any) {
        return ApiService.postFormData("upload_file", object, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.ATTACH_FILES](objectData: any) {
        const params = {} as object;
        if (objectData.idMedia) {
            // @ts-ignore
            params['idMedia'] = objectData.idMedia;
        }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        const headers = {
            'Authorization': `Bearer ${JwtService.getToken()}`
        };
        return fetch(process.env.VUE_APP_API_URL + "/documents/attach_files" + queryString, {headers})
            .then(data => {
                return data.blob();
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.DELETE_FILE_DOCUMENT](objectData: any) {
        const params = {} as object;
        if (objectData.type_file) {
            // @ts-ignore
            params['type_file'] = objectData.type_file;
        }
        if (objectData.id_doc) {
            // @ts-ignore
            params['id_doc'] = objectData.id_doc;
        }
        if (objectData.idMedia) {
            // @ts-ignore
            params['idMedia'] = objectData.idMedia;
        }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
            return ApiService.delete("documents/delete_file" + queryString)
                .then(({data}) => {
                    return data;
                })
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                });
        }
    }

    @Action
    [Actions.EVALUATION_REPORT](objData: any) {
        const params = {} as object;
        if (objData.site) {
            // @ts-ignore
            params['listSites'] = objData.site;
        }
        if (objData.document) {
            // @ts-ignore
            params['documentX'] = objData.document;
        }
        if (objData.status) {
            // @ts-ignore
            params['statusX'] = objData.status;
        }
        if (objData.join_AP) {
            // @ts-ignore
            params['join_APX'] = objData.join_AP;
        }
        if (objData.nb_actions_plan) {
            // @ts-ignore
            params['nb_actions_plan'] = objData.nb_actions_plan;
        }
        if (objData.is_reassess) {
            // @ts-ignore
            params['is_reassess'] = objData.is_reassess;
        }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        return ApiService.post("evaluations_report", params)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.ASSIGN_SITES_BY_DOCUMENT](objectData: any) {
        const params = {} as object;

        if (objectData.document_id) {
            // @ts-ignore
            params['document'] = objectData.document_id
        }
        if (objectData.user_id) {
            // @ts-ignore
            params['user_id'] = objectData.user_id
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }


        return ApiService.get("site_affectation" + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.PUT_EVALUATION_DUPLICATE](objectData: any) {
        const params = {} as object;
        if (objectData.sites) {
            // @ts-ignore
            params['sites'] = objectData.sites
        }
        if (objectData.compliances) {
            // @ts-ignore
            params['compliances'] = objectData.compliances
        }
        if (objectData.document_id) {
            // @ts-ignore
            params['document'] = objectData.document_id
        }
        if (objectData.site_source) {
            // @ts-ignore
            params['site_source'] = objectData.site_source
        }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        return ApiService.put('evaluation_duplicate', objectData)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_EVALUATIONS](objectData: any) {
        const params = {} as object;
        if (objectData.document) {
            // @ts-ignore
            params['document'] = objectData.document;
        }
        if (objectData.text) {
            // @ts-ignore
            params['compliance.title'] = objectData.text;
        }
        if (objectData.text) {
            // @ts-ignore
            params['section.title'] = objectData.text;
        }
        if (objectData.site) {
            // @ts-ignore
            params['site'] = objectData.site
        }

        if (typeof objectData.page !== "undefined") {
            // @ts-ignore
            params['page'] = objectData.page
        }
        if (typeof objectData.limit !== "undefined") {
            // @ts-ignore
            params['limit'] = objectData.limit
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }

        return ApiService.get("evaluations" + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_STAT_CONFORMITE_SITE](objData: any) {
        const params = {} as object;
        if (objData.site) {
            // @ts-ignore
            params['site'] = objData.site;
        }
        if (objData.sites_fils) {
            // @ts-ignore
            params['sites_fils'] = objData.sites_fils;
        }
        if (objData.month) {
            // @ts-ignore
            params['month'] = objData.month;
        }

        if (objData.type_site) {
            // @ts-ignore
            params['type'] = objData.type_site
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        return ApiService.get("stat_conformite_site" + queryString, objData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.SAVE_QUIZ](data: any) {
        return ApiService.post("site_questions/save_questions", data)
            .then((data) => {
                return data
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
                return response
            });

    }

    @Action
    [Actions.GET_QUIZ](data: any) {
        let queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        if (queryString)
            queryString = '?' + queryString;
        return ApiService.get("questions/questions_list" + queryString, false)
            .then(({data}) => {
                return data
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
                return response
            });
    }

    @Action
    [Actions.GET_PROCESSING_TASKS](objData: any) {
        const params = {} as object;
        if (objData.site_id) {
            // @ts-ignore
            params['site_id'] = objData.site_id;
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        return ApiService.post("list_processing_tasks_site", objData)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_STAT_ECR](objData: any) {
        const params = {} as object;
        if (objData.sites) {
            // @ts-ignore
            params['sitesx'] = objData.sites;
        }
        if (objData.sites2) {
            // @ts-ignore
            params['sites2'] = objData.sites2;
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        return ApiService.post("stat_ECR_100", objData)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_MEDIA_ID](objData: any) {
        return ApiService.get("media/" + objData.idMedia, objData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_SITE_BY_PARENT](objData: any) {
        const params: Object = {} as object;
        if (objData.entity) {
            // @ts-ignore
            params['entity'] = objData.entity
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        if (objData.entity && objData.entity != 'null'){
            return ApiService.get("list_site_of_parent" + queryString, objData.customHeader)
                .then(({data}) => {
                    return data;
                })
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_ERROR, response.data.errors);
                });
        }

    }

    @Action
    [Actions.GET_ROLE_USER_SITE](objData: any) {
        const params: Object = {} as object;
        if (objData.user_id) {
            // @ts-ignore
            params['id_user'] = objData.user_id
        }
        if (objData.site_id) {
            // @ts-ignore
            params['id_site'] = objData.site_id
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        return ApiService.get("user_site_role" + queryString, objData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.DESAFFECT_DOCUMENTS](documents: any) {
        // ApiService.setCustomToken(token);
        return ApiService.post("desaffecation_documents/site", documents)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.DELETE_DOCUMENT_SITE](objData: any) {
        // ApiService.setCustomToken(token);
        return ApiService.post("delete_document_site", objData)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GLOBAL_CONFORMITY_RATP](objData: any) {
        return ApiService.post("stat_global_conformity_ratp", objData)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }
      
        @Action
        [Actions.DELETE_SITE](objData: any) {
        return ApiService.post("delete_site", objData)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
        [Actions.DELETE_CUSTOMER](objData: any) {
        return ApiService.post("delete_customer", objData)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.GET_ACTION_PLAN_REPORT](objectData: any) {
        const params = {} as object;

        if (typeof objectData.page !== "undefined") {
            // @ts-ignore
            params['page'] = objectData.page
        }
        if (typeof objectData.limit !== "undefined") {
            // @ts-ignore
            params['limit'] = objectData.limit
        }
        if (typeof objectData.site !== "undefined") {
            // @ts-ignore
            params['listSites'] = objectData.site
        }
        if (typeof objectData.is_ap_expired !== "undefined") {
            // @ts-ignore
            params['is_ap_expired'] = objectData.is_ap_expired
        }
        if (typeof objectData.user_id !== "undefined") {
            // @ts-ignore
            params['user_id'] = objectData.user_id
        }
        if (typeof objectData.customHeader !== "undefined") {
            // @ts-ignore
            params['customHeader'] = objectData.customHeader
        }
         if (typeof objectData.is_master_admin !== "undefined") {
                // @ts-ignore
                params['is_master_admin'] = objectData.is_master_admin
            }



        // if (typeof objectData.evaluation !== "undefined") {
        //     // @ts-ignore
        //     params['evaluation'] = objectData.evaluation
        // }
        //
        // if (typeof objectData.site !== "undefined") {
        //     // @ts-ignore
        //     params['filter_site'] = objectData.site
        // }
        //
        // if (objectData.title) {
        //     // @ts-ignore
        //     params['title'] = objectData.title
        // }
        //
        // if (objectData.start_date_range_start) {
        //     // @ts-ignore
        //     params['start_date_range_start'] = objectData.start_date_range_start
        //     // params['start_date[after]'] = objectData.start_date
        // }
        //
        // if (objectData.start_date_range_end) {
        //     // @ts-ignore
        //     params['start_date_range_end'] = objectData.start_date_range_end
        //     // params['start_date[after]'] = objectData.start_date
        // }
        // if (objectData.end_date_range_start) {
        //     // @ts-ignore
        //     // params['end_date[after]'] = objectData.end_date
        //     params['end_date_range_start'] = objectData.end_date_range_start
        // }
        // if (objectData.end_date_range_end) {
        //     // @ts-ignore
        //     // params['end_date[after]'] = objectData.end_date
        //     params['end_date_range_end'] = objectData.end_date_range_end
        // }
        // if (objectData.revised_end_date_range_start) {
        //     // @ts-ignore
        //     // params['revised_end_date[after]'] = objectData.revised_end_date
        //     params['revised_end_date_range_start'] = objectData.revised_end_date_range_start
        // }
        //
        // if (objectData.revised_end_date_range_end) {
        //     // @ts-ignore
        //     params['revised_end_date_range_end'] = objectData.revised_end_date_range_end
        // }
        //
        // if (objectData.user_pilot) {
        //     // @ts-ignore
        //     params['user_pilot'] = objectData.user_pilot
        // }
        // if (objectData.user_in_charge) {
        //     // @ts-ignore
        //     params['user_in_charge'] = objectData.user_in_charge
        // }
        //
        // if (objectData.priority) {
        //     // @ts-ignore
        //     params['priority'] = objectData.priority
        // }
        // if (objectData.rate_progress_start) {
        //     // @ts-ignore
        //     params['rateProgress[gte]'] = objectData.rate_progress_start
        // }
        // if (objectData.rate_progress_end) {
        //     // @ts-ignore
        //     params['rateProgress[lte]'] = objectData.rate_progress_end
        // }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }


        return ApiService.get("action_plans_report" + queryString, objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }
    @Action
    [Actions.GET_STATICS_EVALUATIONS](objectData: any) {

        const params = {} as object;

        if (objectData.sites) {
            // @ts-ignore
            params['sites'] = objectData.sites
        }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        return ApiService.post('report_evaluations_statistics', params)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });

    }

    @Action
    [Actions.GET_DOMAINS_WITH_STATUS](objectData: any) {

        const params = {} as object;

        if (objectData.sites) {
            // @ts-ignore
            params['sites'] = objectData.sites
        }
        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        return ApiService.post('domains_with_status', params)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });

    }
    @Action
    [Actions.GET_ALL_EVALUATION_STATUS](objectData: any) {


        return ApiService.get("evaluation_statuses", objectData.customHeader)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }
}
